<template>
<v-container pa-6 class="container" my-6>
  <!--offer-image /-->
  <v-row>
    <v-file-input
    truncate-length="15"
    accept="image/*"
    multiple
    @change="doSomethingWithFiles"
    ></v-file-input>   
  </v-row>



  <!--offer-image :sticker_bg="sticker_image" imageData="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpGPOdjV1LqRi6w2l1u2N1vRviP93CZbi9PABlmlWUlI8FxllWNSzBvTh6Kuag1wF9fro&usqp=CAU"/-->
  
    <offer-image 
    v-for="file in uploaded_files" :key="file.name" 
    
    :sticker_bg="sticker_image" 
    :source_f_obj="file"
    v-bind:ref="'offer-image_' + file.name"
    />
    


    <v-row>
        <v-col cols="4">
          <v-img
            @load="onImageLoaded"
            :src="imageData"
            class="my-3"
            contain
            height="200"
          />
        </v-col>

      <v-btn
        @click="onDownloadAll"
        elevation="2"
        depressed
        color="primary"
        x-large
        block  
        v-bind:disabled="uploaded_files.length==0"
      >
        Download All
      </v-btn>
    </v-row>
</v-container>
  
</template>

<script>
import OfferImage from '../components/OfferImage'
//import OfferImage from '../components/OfferImage.vue'
//import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'Home',

    components: {
        OfferImage
    },
    data() {
      return {
        sticker_image: null,
        imageData: "",
        uploaded_files :[]
        }
    },
    computed: {
      onStickerImageChanged: function(){
        console.log("Sticker image changed to : ", this.sticker_image)
      }
    },
    created() {
      
      // Code that will run only after the
      // entire view has been rendered
      console.log("Home Page Mounted")

      let load_sticker_promis =  this.loadStickerBG()
      load_sticker_promis.then( 
        (img) => {            
                console.log ("Now will Set Sticker Image Data in Main component...")
                this.sticker_image = img
                this.imageData = img.src
                console.log('Sticker Image : ', this.sticker_image)
                console.log('Sticker Image data: ', this.imageData)

              });

      
    },
    methods:{
      onDownloadAll: function(event) {
        if (this.uploaded_files.length == 0)
        {
          alert("You need to upload images first ")
          return
        }

        let offer_images = this.$children.find(child => { return child.$options.name.startsWith("OfferImage"); })

        
        this.$children.forEach(child => {
          if (child.$options.name.startsWith("OfferImage"))
          {
            child.autoDownloadCanvas(null)         
          }
          
        }); 
        let n = 4
      },
      onImageLoaded: function(event) 
      {
        console.log("Image was Loaded")
      },
      loadStickerBG: function() 
      {
        console.log ("Start Loading Sticker Background image...")
        return new Promise ((resolve, reject) => {
        let reader = new FileReader ()

          //let label_url = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpGPOdjV1LqRi6w2l1u2N1vRviP93CZbi9PABlmlWUlI8FxllWNSzBvTh6Kuag1wF9fro&usqp=CAU"
          let label_url = "https://w7.pngwing.com/pngs/112/292/png-transparent-sticker-paper-label-template-others-miscellaneous-infographic-price-tag.png"
          label_url="https://cdn.pixabay.com/photo/2013/07/12/19/31/badge-154930_960_720.png"

          var sticker_img = new Image()
          //sticker_img.crossOrigin="anonymous"
          sticker_img.onload = function() {
            console.log ("Loading Sticker Background image finished.")
            sticker_img.crossOrigin="anonymous"
            resolve (sticker_img) 
          }
          
          sticker_img.src = label_url
        })
      },

      doSomethingWithFiles:function(event){
          //fileInput.addEventListener('change', (e) => doSomethingWithFiles(e.target.files));
          console.log("files were loaded :" , event.length)
          this.uploaded_files = event
      }
      

    }
    
  }
</script>
