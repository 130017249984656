<template>
  <v-container pa-6 class="container" my-6>
    <h1>This is an Offer Image Componenent</h1>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          @load="onImageLoaded"
          :src="sourceImage.src"
          class="my-3"
          contain
          height="200"
        /> 

        <v-img
          v-show="false"
          @load="onImageLoaded"
          :src="sticker_bg.src"
          class="my-3"
          contain
          height="200"
        /> 
        
      </v-col>     

    </v-row>


    <v-row class="text-center">
        <v-col cols="12">
          <canvas id = 'cvs' ref="ref_cvs" width="1080" height="1080"/>          
        </v-col>     

    </v-row>

    <v-row class="text-center">
        <v-col class="mb-3">


          <v-card
            elevation="2"
            outlined
          >
            <h3  class="text-h5" >
              العنوان
            </h3>


          <v-card-text>

            <v-text-field
              v-model="title"
              label="العنوان"
            ></v-text-field>



            <v-row class="text-center">
              <v-col class="mb-9">
            <v-slider
              v-model="title_position"
              vertical
              label="عدل موقع العنوان عموديا"
              :max="title_pos_max"
              :min="title_pos_min"
            ></v-slider>
              </v-col >
              <v-col class="mb-3">
                {{ title_position }}
              </v-col>
              <v-col class="mb-3">
                  <v-subheader>لون خط العنوان</v-subheader>
                  <v-color-picker
                    v-model="titleTextColor"
                    class="ma-2"
                    hide-canvas
                  ></v-color-picker>
              </v-col>          
              
            </v-row>
          </v-card-text>

          </v-card>

   
          
          <v-card
              class="mt-6"
              elevation="2"
              outlined
          > 
          
             <h3  class="text-h5">
              السعر
            </h3>  

            <v-card-text> 

            <v-row>
            
            
            <v-col cols="3">
              <v-text-field
                v-model="price"
                :rules="[
                  () => price!='' || !isNaN(price) || 'Price Should be a number',
                  
                ]"
                label="السعر"
                value="10.00"
                prefix="JOD"
              ></v-text-field>
            </v-col>



            <v-col cols="6">
              <v-subheader>موقع ملصق السعر</v-subheader>
              <v-card-text>
                <v-slider
                  v-model="priceLocation"
                  :tick-labels="priceLocationLabels"
                  :max="3"
                  step="1"
                  ticks="always"
                  tick-size="4"
                ></v-slider>
                
              </v-card-text>
            </v-col>

            <v-col cols="3">
                <v-subheader>لون خط السعر</v-subheader>
                <v-color-picker
                  v-model="priceTextColor"
                  class="ma-2"
                  hide-canvas
                ></v-color-picker>
            </v-col>


            </v-row>

            </v-card-text>
           

          </v-card>



          <v-card
              class="mt-6"
              elevation="2"
              outlined
          > 

          <v-card-title>ملاحظات</v-card-title>

          <v-card-text>

            <v-textarea
                v-model="description"
                :rules="[
                  () => description.length < 100 || 'description length Should be less than 100 characters',
                  //addressCheck
                ]"
              name="input-7-1"
              label="Description"
              value="aaaaaa"
              hint="Hint text"
            ></v-textarea>
          </v-card-text>

          </v-card>


        </v-col>

    </v-row>

    <a id="link" ref="ref_dnld" @click="autoDownloadCanvas()">Click me to download</a>
  </v-container>
</template>

<script>
  export default {
    name: 'OfferImage',
    props: ['sticker_bg', 'sourceImage', 'source_f_obj'],
    data() {
          return {
            title_pos_min : -10,
            title_pos_max : 10,
            //sourceImage: "" //you might not need this
            price: '', priceLocation : 0, 
            priceLocationLabels: [
              'TopLeft',
              'TopRight',
              'BottomLeft',
              'BottomRight',
            ],
            priceTextColor: '#FFEE11FF' , 


            title: '',
            title_position: 0,
            titleTextColor: '#00FF21FF' , //{ "hexa":"#00FF21FF"},
            description: '',

          }
    },
    
    computed: {
      fileWasSet: function () {
      // `this` points to the vm instance
      // return this.message.split('').reverse().join('')
      console.log("File " + this.source_f_obj.name + "Was Set for this component")
    }
    },
    watch: {
      // whenever question changes, this function will run
      price(newPrice, oldPrice) {
        if (newPrice > 0) {
          this.reRenderCanvas()
        }
      },
      title(newTitle, oldTitle) {
           this.reRenderCanvas()        
      },
      description(newTitle, oldTitle) {
           this.reRenderCanvas()        
      },
      title_position(newPosition, oldPosition) {
           this.reRenderCanvas()        
      },
      sticker_bg(newBG, oldBG){
        console.log("inside component : Sticker Background was set")
      },
      priceLocation(newLocation, oldLocation) {
        this.reRenderCanvas()
      },
      titleTextColor(newTitleTextColor, oldTitleTextColor){
        this.reRenderCanvas()
      },
      priceTextColor(newPriceTextColor, oldPriceTextColor){
        this.reRenderCanvas()
      }

    },
    created() {
      
          // Code that will run only after the
          // entire view has been rendered
          console.log("Offer image component create with file " , this.source_f_obj.name)

                console.log("Home Page Mounted")

          let load_sticker_promis =  this.blobToImg(this.source_f_obj)
          load_sticker_promis.then( 
            (img) => {            
                    console.log ("Now will Set Sticker Image Data in Main component...")
                    //this.sticker_image = img
                    this.sourceImage = img
                    //console.log('Sticker Image : ', this.sticker_image)
                    //console.log('Sticker Image data: ', this.sourceImage.src)

                  });

    },
    methods:{
      blobToImg (blob) {
          // blob is file system object (Name, Size, Type)
        return new Promise ((resolve, reject) => {
          let reader = new FileReader ()
          reader.addEventListener ('load', () => {
            let img = new Image ()
            img.crossOrigin="anonymous"
            img.src = reader.result
            img.addEventListener ('load', () => resolve (img))
          })
          reader.readAsDataURL (blob)
        })
      },


      onImageLoaded(){
        console.log("Inside Component Image was Loaded")
        
        this.reRenderCanvas()
      },
      onStickerLoaded(){
        console.log("Inside Component Sticker Background image loaded")
      },
      getPricePositionsFromLocation(){
        let cvs = this.$refs.ref_cvs
        let price_bg_img = this.sticker_bg
        let sticker_width = 200 // price_bg_img.width
        let sticker_height = 200 // price_bg_img.height
        switch(this.priceLocation){
          case 0: return [20, 20, sticker_width, sticker_height]; break; // Top Left
          case 1: return [cvs.width - sticker_width - 20 , 20, sticker_width, sticker_height]; break; // Top Right
          case 2: return [20, cvs.height - sticker_height - 120, sticker_width, sticker_height]; break; // Bottom Left
          case 3: return [cvs.width - sticker_width - 20, cvs.height - sticker_height - 120, sticker_width, sticker_height]; break; // Bottom Right
          default: return [20,20, sticker_width, sticker_height]; break;
        }
        return [20,20, sticker_width, sticker_height]
      },
      drawImageScaled(img, ctx) {
        var canvas = ctx.canvas ;
        var hRatio = canvas.width  / img.width    ;
        var vRatio =  canvas.height / img.height  ;
        var ratio  = Math.min ( hRatio, vRatio );
        var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
        var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
        ctx.clearRect(0,0,canvas.width, canvas.height);
        ctx.drawImage(img, 0,0, img.width, img.height,
                            centerShift_x,centerShift_y,img.width*ratio, img.height*ratio);  
      },
      reRenderCanvas(){
        console.log("Canvas rerendered.." + this.title)

              // img : is Image Tag that has src = to "data" schema
        //let img = this.sourceImage
      
        let canvas = this.$refs.ref_cvs //$el["#canvas"] // document.createElement ('canvas')
        //canvas.width = img.width
        //canvas.height = img.height
        let ctx = canvas.getContext ('2d', true)
        ctx.font = '40px Arial';
        
        //ctx.save();
        
       // ctx.restore();

        ctx.lineWidth = 10;
        this.drawImageScaled(this.sourceImage, ctx)
        //ctx.drawImage (img, 0, 0)
        
        if (this.price != '')
        {
          let choosenPositions = this.getPricePositionsFromLocation()
          let sticker_x = choosenPositions[0] //20
          let sticker_y = choosenPositions[1] //20

          let sticker_width = choosenPositions[2] //this.sticker_bg.width
          let sticker_height = choosenPositions[3] // this.sticker_bg.height
          ctx.drawImage(this.sticker_bg, sticker_x, sticker_y, sticker_width,sticker_height)
          let price_text_dims = ctx.measureText(this.price)

          let price_text_width = price_text_dims.actualBoundingBoxRight
          let price_text_height = price_text_dims.actualBoundingBoxAscent

          let start_x = sticker_x + sticker_width / 2 - price_text_width / 2
          let start_y = sticker_y + (sticker_height / 2 - price_text_height/2 ) + price_text_height



            //let opacity = .90;             
            //ctx.fillStyle = `rgba(51, 170, 51, ${opacity})`;
            ctx.fillStyle = this.priceTextColor
            
            console.log (`fill price text on ${sticker_x} , ${sticker_y} , while text pos ${start_x}, ${start_y}`)
            ctx.fillText(this.price, start_x, start_y);


        }
        
        

        //ctx.strokeRect(75, 140, 150, 110);        
        //ctx.fillRect(0, 0, 50, 200 );
        
        if (this.title != '')
        {
            let caption = this.title     
        
            let text = ctx.measureText(caption);
            console.log(text)
            console.log("Text Width in Px: ",  text.width);  // 56; 

            let bounding_box_start_x = 0
            let bounding_box_end_x = canvas.width

            let bounding_box_start_y = canvas.height - 50 - text.actualBoundingBoxAscent - this.title_position - 10
            //let bounding_box_end_y = canvas.height - 50 - this.title_position



            
            let opacity = .25;           
            ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`; 
            ctx.fillRect(bounding_box_start_x, bounding_box_start_y, bounding_box_end_x, text.actualBoundingBoxAscent +  20 );
            
            console.log (`Bounding box is ${bounding_box_start_x} , ${bounding_box_start_y} to ${bounding_box_end_x}, ${text.actualBoundingBoxAscent}`)
            



            let text_width = text.actualBoundingBoxRight
            

            let start_x = canvas.width / 2 - text_width / 2
            let start_y = bounding_box_start_y + text.actualBoundingBoxAscent + 10



            opacity = .50;
            
            
            ctx.fillStyle = this.titleTextColor//`rgba(51, 170, 51, ${opacity})`;
            
            //start_x = start_y = 20
            ctx.fillText(caption, start_x, start_y);

        }

     
      },

    autoDownloadCanvas(event) {
      let canvas = this.$refs.ref_cvs
      let link = this.$refs.ref_dnld
      let name = `example_${this.source_f_obj.name}.png`
      link.setAttribute('download', name);
      link.setAttribute('href', canvas.toDataURL("image/png"));
      link.click();
    }



    },
        
  }
</script>

<style scoped>
.container
{
  border-width: 10px ;
  background-color: coral;
}
</style>